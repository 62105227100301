import React, { useEffect,useRef,useState } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';
import Moment from 'react-moment';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import download from 'downloadjs'
import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';




const Bank_info = () => {
    let navigate = useNavigate();
    const [state, setState] = React.useState({
        shipmnent_list: [],
        dataCount: 0,
        isLoading: true,
        dialogOpen: false,
        searchValue: "",
     })
   

  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
const[modals,setModals] = React.useState({show: false,invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});

const [searchfilters, setsearchFilters] = React.useState({ searchtitle: "",filter:[{name:"booked",value:1,status:true},{name:"assigned",value:2,status:true},{name:"intransit",value:3,status:true},{name:"delivered",value:4,status:true}],filterStatus:[]})
console.log("filter_status ==" ,searchfilters )
// const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
const[dimensionState,setdimensionState] = React.useState([])
const [isLoading, setIsLoading] = useState(false);
const [setDate, setDateState] = useState({date:""});
const [focusedIndex, setfocusedIndex] = React.useState(-1);
const [searchwithstate, setsearchwithstate] = React.useState({search_with:"order"});
const [searchshipment , setsearchshipment] = React.useState({shipment_id:""})

  const[customeridstate , setcustomeridstate]= React.useState({customer_id:""})

React.useEffect(()=>{
    axios_get_api(0,true)
},[])

    const axios_get_api = (index=0,onLoad = true) => {
        //  console.log("dstatus--->",shipment_status)
         console.log("onLoad--->",onLoad)
         setIsLoading(true)
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_bank_information`;
        let sendData = {
            find:"",
            // shipment_status: shipment_status,
            indexValue: index,
            limit:otherStates.rowsPerPage,
           
        };
        console.log("sendData",sendData)
            sendData.find= searchshipment.shipment_id
      
       
         console.log("send",sendData)
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setIsLoading(false)
           
            if(index==0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.dataCount ,  page:index})         
            }
           
         setState({ ...state, shipmnent_list: res.data.output, dataCount: res.data.dataCount,isLoading:false });
          
          console.log( "response",res.data.output);
     
        }).catch((e) => {
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                
              })
            console.log("----error:   ", e);
        })

    }


 

  
      


   
   
    
     const handlePageChange = (event,newPage) =>{
        // console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        axios_get_api(newPage,true);
        
       // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
     console.log("event ", event.target.value);
     console.log("newPage ", newPage);
       setOtherState({...otherStates, rowsPerPage:event.target.value, page:0})
       
       
    }
  

      
  return (
    <div>
        <Sub_header/>
        <div style={{marginLeft:"15px",marginRight:"15px"}}>
         <section class="mt-3">
         <div class="row mb-3">
            <div class="col-12 col-md-2">
                <h5>Customer Bank Details</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                   
                </div>
            </div>
        
      
        </div>
        {(isLoading) ?
                <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
            
                 <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
                 
                
                <table class="table table-hover table-bordered shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Customer Id#</th>
                            <th class="border-gray-200">Customer Name</th>	

                            <th class="border-gray-200">Account Name</th>
                            <th class="border-gray-200">Bank Account</th>	
                            <th class="border-gray-200 ship_pickup">IFSC</th>
                           
                        </tr>
                    </thead>
                   
                    <tbody>
                      {state.shipmnent_list.map((item)=>
                        <tr>
                           <td>{item.customer_id}</td> 
                            <td>{item.full_name?item.full_name:""}<br/>
                            {item.email?item.email:""}<br/>
                            {item.company_name?item.company_name:""}
                            </td>                     
                            <td>
                            {item.account_name}  
                         
                            </td> 
                            <td>  {item.bankAccount}</td>                       
                            <td>
                            {item.ifsc}   
                            </td>
                          
                        </tr>
                      )}                        
                    </tbody>

                </table>
                <TablePagination
                            component="div"
                            rowsPerPageOptions={[20,25,30,35,40,45,50]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
                        
                      
                        </div> 
}
              
                    

    </section>
    </div>

        </div>
  )
}

export default Bank_info