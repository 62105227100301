import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
const Add_zone_pincode = () => {
    let navigate = useNavigate()
    let {customer_id} = useParams();
    const [state, setState] = useState({ isLoading: true, user_list: [] });
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });
    const [isLoading, setIsLoading] = useState(false);
    const [productList, setproductList] = useState([]);
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [addstate , setaddstate] = React.useState({from_pincode:"" , to_pincode:"" , zone:""})
    console.log("addstate ==== " , addstate)
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })

    React.useEffect(()=>{
        getZoneList()
        getUsersList(0, true);
    },[])
   

    const getZoneList = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/system_zones`;
        let sendData = {  dstatus:1,
           };
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
        
         setproductList(res.data.output)


        }).catch((e) => {


        });
    }
    const handleChange=(e)=>{
        setaddstate({...addstate , [e.target.name]:e.target.value})
    }

    const onsubmit =()=>{
if(addstate.from_pincode!="" && addstate.to_pincode!="" && addstate.zone!=""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_pincode_zone`;
        let sendData = {  from_pincode:addstate.from_pincode , to_pincode:addstate.to_pincode , zone:addstate.zone};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
           if(res.data.status==true){
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message,
                color:"white"
              });
              getUsersList(0, true);
              setaddstate({from_pincode:"" , to_pincode:"" , zone:""})
           }
           else{
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
           }

        }).catch((e) => {
            Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });

        });
    }else{
        Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Please fill all fields",
            color:"white"
          });
    }
    }

    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/pincode_zone_list`;
        let sendData = { find: "",   limit: otherStates.rowsPerPage, indexValue: index };

      
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
           
            if (index == 0 && onLoad) {
                setOtherState({ ...otherStates, total_count: res.data.count })

            }
            setState({ ...state, user_list: res.data.output, isLoading: false })



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const handlePageChange = (event, newPage) => {
        setOtherState({ ...otherStates, page: newPage })
        //console.log("newPage ", newPage);
        getUsersList(newPage, true, otherStates.rowsPerPage);
    }
    const handleChangeRowsPerPage = (event) => {
        //console.log("event ", event.target.value);        
        setOtherState({ ...otherStates, rowsPerPage: event.target.value })
        getUsersList(0, true, event.target.value)
    }

    const csvinputEditHandleChange = (e) => {        
        if (e.target.type == "file") {            
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        } else {
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
        }
      }
      const uploadPincodeFile= () => {
       
        if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
           // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = uploadModalState.excel_fle[0].name;
           // console.log("fileName =======>                 ",fileName);
           
           // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
           // return 
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_bulk_zone_pincodes`;
         let bodyFormData = new FormData()
        // console.log("bb", editState);
        //  bodyFormData.append("pincode", editState.country)
       
         bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
         
       // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
        console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);
  
            setUploadModalState({...uploadModalState, show: false });
          //  setEditState({ ...editState, state_name: "", state_id: "" })
  
           
            if (res.status == 200 && res.data.status == "success") {
             
               // setTimeout(getStateList(0,true), 5000);
               const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Bulk Import Process Starts",
                    color: "white"
                });
                setTimeout(()=>{
                  navigate(`/zone_pincode_log/${res.data.record_id}`)
                },1000)
                
            }
            
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
  
                })
            }
  
            // navigate('/categories')
            // let newCategory = res.data.category;
            //setState(state.concat([newCategory]))
        }).catch((e) => {
  
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
  
            })
        });
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
  
   
  
  }

  const deleteConfirm = (sub) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        //console.log("result  ",result);
        if (result.isConfirmed) {
            // setIsLoading(true)
            Delete_pincdoe(sub)
        }
    })

 }
 const Delete_pincdoe = (sub) => {
    try {
        
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/delete_pincode_zone`;
            let bodyFormData = { from_pincode: sub.from_pincode, zone_id:sub.zone_id , to_pincode:sub.to_pincode}

            console.log("bb", bodyFormData);
            axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
                console.log("res ", res);
                 
                getUsersList(0, true);
                if (res) {
                    Swal.fire(
                        'Good job!',
                        ' Deleted Successfully !',
                        'success'
                    )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                }

                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
            }).catch((e) => {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            });
       
    } catch (e) {
        console.log(e);
    }

}
  return (
    <div>
    
    <Sub_header/>
    
    
                <div style={{ marginLeft: "15px", marginRight: "15px" }}>
        <section>
    
        <div class="row py-4">
            <div class="col-12 col-md-6">
                <h2 class="h4">Add Pincode</h2>
            </div>
            <div class="col-12 col-md-6 text-end">
        <a href="#" class="btn btn-sm btn-gray-800 d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#import_pincode_special">
            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            Upload Servicable Pinocdes
        </a>
    </div>
    <div class="modal" id="import_pincode_special">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Bulk Servicable Pincode</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="../../../assets/doc/uplaod_zone_pincodes_format.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
        <hr/>
      
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>      
        </div>

        <div className="row ms-5">
            <div className="col-3">
                <h6>Origin Pincode</h6>
<input type="number" className="form-control" name="from_pincode" placeholder="Enter Origin Pincode" onChange={(e)=>handleChange(e)} />
            </div>
            <div className="col-3">
            <h6>Destination Pincode</h6>

<input type="number" className="form-control"  name="to_pincode"  placeholder="Enter Destination Pincode" onChange={(e)=>handleChange(e)}/>
                
            </div>
            <div className="col-3">
            <h6>Select Zone</h6>

                <select type="select" className="form-select" placeholder="Select Zone" name="zone" onChange={(e)=>handleChange(e)}>
                    <option>Select</option>
                    {productList.map((sub)=>(
                    <option value={sub.zone_id}>{sub.zone_name}</option>
                    ))}
                </select>
            </div>

            <div className="col-3 mt-4">
                <button className="btn btn-primary" onClick={(e)=>onsubmit(e)}>Submit</button>
            </div>

        </div>
        {(isLoading) ?
                        <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                            <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                                <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                            </div>
                        </div> :
                        <div class="card card-body border-0 shadow table-wrapper table-responsive mt-5">

                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th class="border-gray-200">Origin Pincode</th>
                                        <th class="border-gray-200">Destination Pincode</th>
                                        <th class="border-gray-200">Zone Name</th>
                                        <th class="border-gray-200">Action</th>

                                        </tr>
                                </thead>

                                <tbody>
                                    {state.user_list.map((user, index) =>
                                      <tr>
                                        <td>{user.from_pincode}</td>
                                        <td>{user.to_pincode}</td>
                                        <td>{user.zone_name}</td>
                                        <td> <div className="btn-group">
                                                    <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="icon icon-sm">
                                                            <span className="fa fa-ellipsis-h icon-dark"></span>
                                                        </span>
                                                        <span className="visually-hidden">Toggle Dropdown</span>
                                                    </button>
                                                    <div className="dropdown-menu py-0">
                                                        
                                                        {/* <a className="dropdown-item" onClick={() => edit_city(item)}><span className="fa fa-edit me-2"></span>Edit</a> */}
                                                        <a className="dropdown-item text-danger rounded-bottom" onClick={() => deleteConfirm(user)}><span className="fa  fa-trash me-2"></span>Remove</a>
                                                    </div>
                                                </div></td>

                                      </tr>
                                    )}
                                </tbody>

                            </table>

                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10]}
                                count={otherStates.total_count}
                                page={otherStates.page}
                                onPageChange={handlePageChange}
                                rowsPerPage={otherStates.rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}

                            />
                         
                        </div>

                    }
        
                
                
                
                        
    
        </section>
        </div>
      
        </div>
  )
}

export default Add_zone_pincode
