import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import Sub_header from '../Sub_header';
import exportValue from "../../apiconfig";

const Customers_info = () => {
    
    const {customer_id} = useParams();
    let admindata = {};
if (localStorage.getItem('admin_login')) {
  admindata = JSON.parse(localStorage.getItem('admin_login'));
  console.log("admindata------>  -  ",admindata);
  //navigate('/home') 
}
   

    const [state, setState] = useState({ isLoading: true, user_info: {} });
    const [limitstate,setlimit] = useState({credit_limit:0})
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getUsersInfo();
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    const getUsersInfo = () => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_detail`;
        let sendData = { customer_id:customer_id };

       
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false) 
            if(res.data.output.length ==1) {       
            setState({ ...state, user_info: res.data.output[0], isLoading: false })
            }



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const updateUsersInfo = () => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_update`;
        let sendData = state.user_info;

       
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            
                 console.log("res", res);
                 if(res.data.status == 200) {
                    Toast.fire({
                        background: "#10b93b",
                        type: 'success',
                        title: "Updated Successfully !",
                        color: "white"
                    });
                 }



        }).catch((e) => {
            setIsLoading(false)

        });
    }

    const inputEditHandleChange = (e) => {
         console.log("e", e);
        // console.log("type", e.target.type);
        // console.log("name", e.target.name);
        // console.log("value", e.target.value);
        // console.log("file", e.target.files);

       let userTemp = {...state.user_info};
      // console.log("userTemp ", userTemp[e.target.name]);
       userTemp[e.target.name] =  e.target.value;
        setState({ ...state, user_info:userTemp });
        
    }

    const handlecreditlimit =(e)=>{
     setlimit({credit_limit:e.target.value})
     let userTemp = {...state.user_info};
      // console.log("userTemp ", userTemp[e.target.name]);
       userTemp[e.target.name] =  e.target.value;
        setState({ ...state, user_info:userTemp });

    }

    const setCreditlimit=()=>{
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_credit_limit_update`;
        let sendData = {customer_id:customer_id , credit_limit:limitstate.credit_limit};
        if(limitstate.credit_limit!=0){
       axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            
                 console.log("res", res);
                 if(res.data.status == 200) {
                    Toast.fire({
                        background: "#10b93b",
                        type: 'success',
                        title: "Updated Successfully !",
                        color: "white"
                    });
                 }
   }).catch((e) => {
           
    Toast.fire({
        background: "#10b93b",
        type: 'success',
        title: "Something Went Wrong",
        color: "white"
    });
        });
    }else{
        Toast.fire({
            background: "#10b93b",
            type: 'success',
            title: "Please Fill credit limit value greater than zero",
            color: "white"
        });
    }
    }

  return (
    <div>
      <Sub_header/>
      <div style={{marginLeft:"15px",marginRight:"15px"}}>
      <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Customers</li>
                        </ol>
                    </nav>
                    <h2 class="h4">{}</h2>
                    
                </div>
              
                <div class="btn-toolbar mb-2 mb-md-0">
                    <span class="badge rounded-pill bg-success">Active</span>                  
                </div>
               
            </div>
            
    </section>


    <section class="mb-3">
        <div class="row">
          <div class="col-12 col-md">
          <section>
        <ul class="nav nav-tabs justify-content-end">
            <li class="nav-item">
              <a class="nav-link active" href="#">Info</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={`/shipment_report?customer_id=${encodeURIComponent(customer_id)}`}>Shipments</a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" href="#">Payments</a>
            </li> */}
        </ul>
    </section>
    <section id="generalinfo">
        <div class="row">
            <div class="col-12 col-xl-12">
                <div class="card card-body border-0 shadow mb-4">
                    <h2 class="h5 my-4">Customer Information</h2>
                    <form>

                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div>
                                    <label for="first_name">Full Name</label>
                                    <input class="form-control" id="first_name" type="text" placeholder="name" required value={state.user_info.full_name} onChange={(e)=>inputEditHandleChange(e)} name="full_name"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={state.user_info.email} onChange={(e)=>inputEditHandleChange(e)} name="email"/>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input class="form-control" id="phone" type="text" placeholder="Enter phone" required value={state.user_info.mobile} onChange={(e)=>inputEditHandleChange(e)} name="mobile"/>
                                </div>
                            </div>
                        </div>
                        <h2 class="h5 my-4">Location</h2>
                        <div class="row">
                            <div class="col-sm-12 mb-3">
                                <div class="form-group">
                                    <label for="address">Address</label>
                                    <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={state.user_info.address} onChange={(e)=>inputEditHandleChange(e)} name="address"/>
                                </div>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="state">Country</label>
                                <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                    <option selected>Country</option>
                                </select>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="state">State</label>
                                <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                    <option selected>State</option>
                                </select>
                            </div>
                            <div class="col-sm-4 mb-3">
                                <label for="state">City</label>
                                <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                    <option selected>City</option>
                                </select>
                            </div>
                        </div>

                        {/* <h2 class="h5 my-4">KYC Inforamtion</h2>
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <label for="">KYC ID</label>
                                <br/>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                  <label class="form-check-label" for="inlineRadio1">Driving License</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                  <label class="form-check-label" for="inlineRadio2">Passport copy</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                    <label class="form-check-label" for="inlineRadio3">National ID</label>
                                  </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="">Number</label>
                                <input type="text" class="form-control" id="text" placeholder="Enter ID number"/>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="">Document</label>
                                <input type="file" class="form-control" id="text" placeholder="Enter details name"/>
                            </div>
                        </div> */}

                        <h2 class="h5 my-4">Update Password</h2>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label for="">Update Password</label>
                                <input type="text" class="form-control" id="text" placeholder="enter new password" name="password" onChange={(e)=>inputEditHandleChange(e)}/>
                            </div>
                        </div>

                        <div class="mt-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>updateUsersInfo()}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
          </div>
          <div class="col-12 col-md-4">
            <section>
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 mb-3">
                        <p> Wallet Balance: {(state.user_info.wallet_balance) ? state.user_info.wallet_balance.toFixed(2):0}</p>
                            {/* <label for="first_name">Customer Type: </label> */}
                            {/* <div>
                           
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="user_type" id="inlineRadio1" value="1" checked={state.user_info.user_type == 1}  onChange={(e)=>inputEditHandleChange(e)}/>
                                  <label class="form-check-label" for="inlineRadio1">Regular Customer</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                  <label class="form-check-label" for="inlineRadio3">Vendor</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="user_type" id="inlineRadio2" value="2" checked={state.user_info.user_type == 2} onChange={(e)=>inputEditHandleChange(e)}/>
                                  <label class="form-check-label" for="inlineRadio2" >Agent</label>
                                </div>
                            </div> */}
                        </div>
                         
                         {admindata.admin_type==2?
                        <div class="col-12">
                            <div class="mb-3">
                                <label for="first_name">Credit Limit:</label>
                                <div class="input-group">
                                    <span class="input-group-text">INR</span>
                                    <input type="text" class="form-control" placeholder="Enter credit amount" name="credit_limit" onChange={(e)=>handlecreditlimit(e)} value={state.user_info.credit_limit}/>
                                  </div>
                            </div>
                            <div  class="mb-3">
                            <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>setCreditlimit()}>Save</button>
                            </div>
                            {/* <div class="mb-3">
                                <label for="first_name">Invoice Cycle:</label>
                                <select class="form-select">
                                  <option>7 Days</option>
                                  <option>15 Days</option>
                                  <option>Monthly</option>
                                </select>
                            </div> */}
                            {/* <div class="mb-3">
                                <label for="first_name">Custom Discount (%):</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="in %" value={state.user_info.discount}/>
                                    <span class="input-group-text">%</span>
                                  </div>
                            </div> */}
                        </div>:""}
                    </div>
                  </div>
                </div>
            </section>
          </div>
        </div> 
    </section>
    
      </div>
    </div>
  )
}

export default Customers_info
