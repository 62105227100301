import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const Payout = () => {
   const[date,setdate] = React.useState({settle_date:""})
   const[modalstate , setmodalstate] = React.useState(false)
   const[isloading , setisloading]= React.useState(false)
   const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });
   const [isLoading, setIsLoading] = useState(false);
   const [state, setState] = useState({ isLoading: true, user_list: [] });
   const handleChange = (e)=>{
    setdate({...date ,settle_date:e.target.value})
   }
   const closeModal = ()=>{
    setmodalstate(false)
    setdate({settle_date:""})
   }

   const getUsersList = (index = 0, onLoad) => {

    setIsLoading(true)
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/payout_list`;
    let sendData = { dstatus: otherStates.dstatus, limit: otherStates.rowsPerPage, indexValue: index };

    // if (searchfilters.searchtitle) {
    //     sendData.searchKeyword = searchfilters.searchtitle
    // }
    console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        setIsLoading(false)
        console.log("response ", res);
        console.log("otherStates ", otherStates);
        if (index == 0 && onLoad) {
            setOtherState({ ...otherStates, total_count: res.data.output.length })

        }
        setState({ ...state, user_list: res.data.output, isLoading: false })



    }).catch((e) => {
        setIsLoading(false)

    });
}

const handlePageChange = (event, newPage) => {
    setOtherState({ ...otherStates, page: newPage })
    //console.log("newPage ", newPage);
    getUsersList(newPage);
}
const handleChangeRowsPerPage = (event) => {
    console.log("event ", event);
}

React.useEffect(()=>{
    getUsersList(0, true);
},[])
   const create_payout = () => {

    setisloading(true)
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/create_payout`;
    let sendData = {settle_date:date.settle_date };

    console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        if(res.data.status==true){
            setisloading(false)
            setmodalstate(false)
            setdate({settle_date:""})
            Swal.fire(
                'Good job!',
                res.data.message,
                'success'
            )
            getUsersList(0, true);
        }
        else{
            setisloading(false) 
            setmodalstate(false)
            setdate({settle_date:""})
            Swal.fire(
                'Oops !',
                'Something Went Wrong',
                'unsuccess'
            )
        }
      }).catch((e) => {
        setisloading(false)
        setmodalstate(false)
        setdate({settle_date:""})
        Swal.fire(
            'Oops !',
            'Something Went Wrong',
            'unsuccess'
        )
     });
}

  return (
    <div>
      <Sub_header/>     
<div style={{marginLeft:"15px",marginRight:"15px"}}>
<Modal show={modalstate} onHide={() =>closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a payout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
          
      <div class="modal-body">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
              <label for="email" class="form-label">Settle Date:</label>
            </div>
            <div class="col-12 col-md">
              <input type="date" class="form-control" id="" placeholder="Settle Date" name="settle_date" onChange={(e)=>handleChange(e)} required=""/>
              <small class="text-danger">The estimated date when the admin will deposit the amount into the customer's account.</small>
            </div>
        </div>
        <small class="text-muted">Payout creation will take some time. Please be patient.</small>
      </div>
<div class="modal-footer">
{isloading? <ReactLoading type={"spin"} color={"#000000"} height={30} width={30} />:
        <button type="button" class="btn btn-primary" onClick={(e)=>create_payout(e)}>Create Payout</button>}
      </div>
           </Modal.Body>
</Modal>

    <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-2">
                <h5>All Payouts</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md">
                        <div class="input-group me-2 me-lg-3 fmxw-400">
                            <select class="form-select">
                              <option>Transcation #</option>
                            </select>
                            <input type="text" class="form-control" placeholder="Search Shipments"/>
                            <span class="input-group-text ">
                                <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <input type="date" class="form-control" id="text" placeholder="Select date"/>
                    </div>
                    <div class="col-12 col-md-2">
                    <div class="btn-group">
                            <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                                Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu">
                                <section class="p-2">
                                <h6>Status</h6>
                                <hr/>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked/>
                                        <label class="form-check-label">Under Process</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked/>
                                        <label class="form-check-label">Completed</label>
                                    </div>
                                </section>
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 text-end">
                   <a onClick={(e)=>setmodalstate(true)} class="btn btn-primary" >Create Payout</a>   
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Payout #</th>	
                            <th class="border-gray-200">Create Date</th>
                            <th class="border-gray-200">Settle Date</th>
                            <th class="border-gray-200">No of Customer</th>
                            <th class="border-gray-200">Total Amount</th>
                            <th class="border-gray-200">Status</th>
                            {/* <th class="border-gray-200">Action</th> */}
                        </tr>
                    </thead>
                    {(isLoading) ?
                <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : 
                    <tbody>
                        {state.user_list.map((sub)=>(
                       
                        <tr>
                            <td>
                                <h5><a href={`/payouts_trans_list/${sub.payout_id}`} class="text-info fw-700 fs-6">{sub.payout_id}</a></h5>
                            </td>                        
                            <td>
                            <Moment format="MMM DD YYYY">
                        {new Date(sub.create_date*1000)}
             </Moment> <small><Moment format="hh:mm:ss a">
                         {new Date(sub.create_date*1000)}
             </Moment> </small>
                            </td>                        
                            <td>
                           {sub.settle_date}
                            </td>
                            <td class="">
                                <strong>{sub.total_customer}</strong>
                            </td>
                            <td class="fw-700 text-danger">
                                {sub.total_amount}
                            </td>
                            <td>
                             {sub.status==0?   <span class="badge bg-warning"> <i class="fa fa-clock-o" aria-hidden="true"></i> Under Process</span>
                             :<span class="badge bg-success"> <i class="fa fa-clock-o" aria-hidden="true"></i>Completed</span>}
                            </td>
                            {/* <td>
                                <a href="payouts_trans_list.php" target="_blank" class="btn btn-outline-info  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="View Deatils">receipt</i></a>
                                <a href="payouts_trans_list.php" class="btn btn-outline-primary  btn-sm"><i class="material-icons-outlined fs-20" data-bs-toggle="tooltip" title="Update Status">refresh</i></a>
                            </td> */}
                        </tr>
                        ))}
                    </tbody>
}
                </table>
                {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-0">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                </div> */}
            </div> 
                    

    </section>
    <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10]}
                            count={otherStates.total_count}
                            page={otherStates.page}
                            onPageChange={handlePageChange}
                            rowsPerPage={otherStates.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}

                        />
    </div>  
    </div>
  )
}

export default Payout
