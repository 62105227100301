import React from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2';
import TablePagination from '@mui/material/TablePagination';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import '../../components/loader.css';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import ReactStarsRating from 'react-awesome-stars-rating';


const User_detail = () => {
    const { user_id } = useParams();

    const [state, setState] = React.useState({ isLoading: true });
    const [customerstate, setCustomerState] = React.useState({ customer_review: [], isLoading: true });
    const [likecountstate, setLikeCountState] = React.useState({ count_customer_likes: [] })
    const [notlikecountstate, setnotLikeCountState] = React.useState({ count_customer_notlikes: [] })
    const [countstate, setCountState] = React.useState({ count_customer_review: [] })
    const [infostate, setInfoState] = React.useState({ customer_info: {}, shipments: "", shipment_count: "", count_liked_shipments: "", count_notliked_shipments: "", count_review: "", reviews: "", payments: "", isLoading: true })
    const [modals, setModals] = React.useState({ show: false })
    const [shipmentstate, setshipStatus] = React.useState({ status: null });
    const [countryList, setcountryList] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);

    const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })


    const dimension_detail = () => {
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
        let sendData = {};

        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
            console.log("rnmmmmm", res);
        }).catch((e) => {
            // toast.configure()
            //toast.error("Some thing went wrong")
            console.log("----error:   ", e);
        })
    }
    React.useEffect(() => {
        dimension_detail();
        customer_info()


    }, [])

    React.useEffect(() => {
        //  axios_get_api()
        customer_info()
    }, [])

    const customer_info = () => {

        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/admin_user_profile`;
        let sendData = {
            admin_id: user_id,
        };
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
            setInfoState({ ...infostate, customer_info: res.data.output[0],
                //  shipment_count: res.data.count_shipments,
                //   count_liked_shipments: res.data.count_liked_shipments, count_notliked_shipments: res.data.count_notliked_shipments,
                //    count_review: res.data.count_review,
                //     shipments: res.data.shipments,
                //      reviews: res.data.reviews,
                //       payments: res.data.payments,
                       isLoading: false });
            getCountryList();

            getStateList(res.data.country)
            getCityList(res.data.state)
            console.log("response user", res);
        }).catch((e) => {
            console.log("----error:   ", e);
        })
    }

    const shipmentFilter = (status) => {
        console.log("action ", status);
        setshipStatus({ ...shipmentstate, status: status })

    }

    const getCountryList = () => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/country_list`;
        let sendData = {};
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
             console.log("res country", res);           
            setcountryList(res.data.output)


        }).catch((e) => {


        });
    }

    const getStateList = (country_id) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/state_list`;
        let sendData = { t_country_id: country_id, limit: 1000 };
        //  console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            // console.log("state ", res);           
            setStateList(res.data.output)


        }).catch((e) => {


        });
    }

    const getCityList = (t_state_id) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/city_list`;
        let sendData = { t_state_id: t_state_id, limit: 1000 };
        // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            // console.log("city  ", res);           
            setCityList(res.data.output)


        }).catch((e) => {


        });
    }
    const onChangeLocationGeneral = (e) => {
        // console.log("e general name ",e.target.name);
        // console.log("e general value ",e.target.value);
        const newdata = { ...infostate };
        newdata[e.target.name] = e.target.value;
        setInfoState(newdata);
        if (e.target.name == "country") {
            getStateList(e.target.value)
            setStateList([]);
            setCityList([]);
        }
        else if (e.target.name == "state") {

            getCityList(e.target.value);
            setCityList([])
        }
    }

    const inputHandleChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        let userTemp = {...infostate.customer_info};
      // console.log("userTemp ", userTemp[e.target.name]);
       userTemp[e.target.name] =  e.target.value;
       //console.log("userTemp ", userTemp);
       setInfoState({ ...infostate, customer_info:userTemp });
    }


    const updateUsersInfo = () => {

       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_admin_profile`;
        let sendData = infostate.customer_info;

       
       // console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            
                 console.log("res", res);
                 if(res.data.status == 200) {
                    Toast.fire({
                        background: "#10b93b",
                        type: 'success',
                        title: "Updated Successfully !",
                        color: "white"
                    });
                 }



        }).catch((e) => {
          

        });
    }

    return (
        <div>
            <Sub_header />
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                <section>
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div class="d-block mb-4 mb-md-0">
                            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                                <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                    <li class="breadcrumb-item">
                                        <a href="#">
                                            <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <h2 class="h4">{infostate.customer_info.full_name}</h2>
                            <small>Member Since: <Moment format="MMM DD YYYY">
                        {new Date(infostate.customer_info.created * 1000)}</Moment></small>

                        </div>
                        <div class="btn-toolbar mb-2 mb-md-0">
                            <span class="badge rounded-pill bg-success">Active</span>
                        </div>
                    </div>

                </section>


                <section id="generalinfo">
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="card card-body border-0 shadow mb-4">
                                <h2 class="h5 my-4">User Inforamation</h2>
                                <form>
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <div>
                                                <label for="first_name">Full Name</label>
                                                <input class="form-control" id="first_name" type="text" placeholder="name" required value={infostate.customer_info.full_name}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input class="form-control" id="email" type="email" placeholder="name@company.com" required value={infostate.customer_info.email}/>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <div class="form-group">
                                                <label for="phone">Phone</label>
                                                <input class="form-control" id="phone" type="text" placeholder="+12-345 678 910" required value={infostate.customer_info.phone}/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3">
                                            <label for="state">Account Type</label>
                                            <select class="form-select w-100 mb-0" id="state" name="admin_type" aria-label="State select example" onChange={(e) => inputHandleChange(e)} value={infostate.customer_info.admin_type}>
                                            <option value="">Select </option>
                                            <option value="3">Staff</option>
                                            <option value="2">Admin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h2 class="h5 my-4">Location</h2>
                                    <div class="row">
                                        <div class="col-sm-12 mb-3">
                                            <div class="form-group">
                                                <label for="address">Address</label>
                                                <input class="form-control" id="address" type="text" placeholder="Enter your home address" required value={infostate.customer_info.address}/>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 mb-3">
                                            <label for="state">Country</label>
                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                <option selected>Country</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-4 mb-3">
                                            <label for="state">State</label>
                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                <option selected>State</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-4 mb-3">
                                            <label for="state">City</label>
                                            <select class="form-select w-100 mb-0" id="state" name="state" aria-label="State select example">
                                                <option selected>City</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h2 class="h5 my-4">Password Reset</h2>
                                    <div class="row">
                                        <div class="col-sm-6 mb-3">
                                            <div class="form-group">
                                                <label for="address">New Password</label>
                                                <input class="form-control" id="address" type="password" placeholder="Enter new password" autocomplete="new-password" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <button class="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={()=>updateUsersInfo()}>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default User_detail