import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'

const Payout_detail = () => {
    let {payout_id , customer_id} = useParams();
    console.log("payout_id " , payout_id)
    console.log("customer_id " , customer_id)

    let navigate = useNavigate()
  
 
   
    const[shipmentdetail , setshipmentdetail]= React.useState([])



    React.useEffect(()=>{
        get_payout_detail()
    },[])
    const get_payout_detail=()=>{
    
       
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/payout_detail_api`;
    let sendData = { payout_id:payout_id , customer_id:customer_id };

    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        
          setshipmentdetail(res.data.shipments)
  })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
  return (
    <div>
          <Sub_header/>     
<div style={{marginLeft:"15px",marginRight:"15px"}}>
 
<section class="mt-3">

<div class="row my-3">
       <div class="col-12 col-md">
            <div class="d-flex">
                <div class="">
                    <h2><small>Total Orders #</small>{shipmentdetail.length}</h2>
                </div>
            </div>
        </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                    <tr>
                    <th class="border-gray-200">Shipment Delivered Date #</th>	
                    <th class="border-gray-200">Awb Number</th>
                    <th class="border-gray-200">Order Number </th>	
                    <th class="border-gray-200">COD Amount</th>	

                   
                  
                </tr>
                    </thead>
                    {shipmentdetail.length>0?
                    shipmentdetail.map((item)=>(
             <tbody>
                <tr>
                    <td>
                        {item.delivery_date}
                    </td>    
                       
                    <td class="fw-700">
                    {item.carrier_tracking_number}
                       
                       
                    </td>    
                    <td class="fw-700 ">
                    {item.order_number}
                    </td>  
                    <td class="fw-700 ">
                    ₹{item.cod_amount}
                    </td>                    
                   
                  
                   
                </tr>
              
            </tbody>
          )):""}
                </table>  
            </div>
            </div>
            </section>
            </div>
    </div>
  )
}

export default Payout_detail
